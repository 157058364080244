/*************************
    Custom Styling
**************************/
.user-profile-header-wrapper {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.user-profile-header {
    font-weight: 200;
    font-size: 20px;
    font-family: BoschSansLight;
}

.user-profile-header-description, .user-profile-attribute-group-description, .user-profile-text-bottom {
    font-size: 0.9rem;
}

.user-profile-attribute-group-header {
    font-size: 1.2rem;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.selectLabel {
    padding-left: 1rem;
    font-family: BoschSansMedium;
    font-size: 0.75rem;
    line-height: 32px;
}

.rb-select.rb-user-profile {
    margin-top: -8px;
    margin-bottom: 8px;
}

/* stylelint-disable selector-no-qualifying-type */
.rb-checkbox.rb-user-profile {
    position: relative;
    height: auto;
    padding: 8px 0;
}

.rb-checkbox.rb-user-profile label {
    color: #000;
    background-color: transparent;
    position: relative;
    left: 0;
    margin: 0;
    line-height: 1.5;
    font-size: 1rem;
    padding-left: 2rem;
    display: inline-block;
    align-items: baseline;
    font-weight: 400;
    font-family: BoschSansRegular;
}

.rb-checkbox.rb-user-profile label::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #8a9097;
    content: '';
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
}

.rb-checkbox.rb-user-profile input[type='checkbox'] {
    width: 0;
    opacity: 0;
    height: 0;
    -moz-appearance: none;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:hover + label, .rb-checkbox.rb-user-profile input[type='checkbox']:active + label {
    cursor: pointer;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:disabled + label {
    cursor: default;
    color: #c1c7cc;
    background-color: transparent;
}

.rb-checkbox.rb-user-profile input[type='checkbox'] + label:hover::before {
    background-color: #71767c;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:active + label::before {
    background-color: #595e62;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:disabled + label::before {
    background-color: #e0e2e5;
    pointer-events: none;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:checked + label::after {
    font-family: Bosch-Icon;
    font-size: 1.5rem;
    line-height: 1;
    height: 1.5rem;
    width: 1.5rem;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    content: "\e0e9";
}

.rb-checkbox.rb-user-profile input[type='checkbox']:checked + label::before {
    background-color: #007bc0;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:checked + label:hover::before {
    background-color: #00629a;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:checked:active + label::before {
    background-color: #004975;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:checked:disabled + label::before {
    background-color: #c1c7cc;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:checked:disabled + label::after {
    color: #8a9097;
}

.rb-checkbox.rb-user-profile input[type='checkbox']:focus-visible + label::before {
    outline: auto;
    outline-offset: 3px;
}
